<template>
  <header class="header">
    <div class="container">
      <div class="header__row">
        <router-link
          :to="{ name: 'Home' }"
          class="header__logo-wrapper"
          @click.native="showMenu = false"
        >
          <img
            src="@/assets/images/logo.svg"
            class="header__logo _left"
            alt=""
          />
        </router-link>
        <div class="header__hamburger visible-xs" @click="showBurger()">
          <img
            v-if="showMenu"
            src="../assets/images/close_icon.svg"
            alt=""
            class="header__hamburger-pic"
          />
          <img
            v-else
            src="../assets/images/hamburger_icon.svg"
            alt=""
            class="header__hamburger-pic"
          />
        </div>
        <div class="header__hamburger hidden-xs" @click="showBurger()">
          <div class="header__hamburger-text">
            {{ showMenu ? "закрыть" : "меню" }}
          </div>
          <img
            v-if="showMenu"
            src="../assets/images/icons/close-white.svg"
            alt=""
            class="header__hamburger-pic"
          />
          <img
            v-else
            src="../assets/images/hamburger_icon-white.svg"
            alt=""
            class="header__hamburger-pic"
          />
        </div>
      </div>
    </div>
    <div class="header__menu" :class="{ _active: showMenu }">
      <router-link
        class="header__menu-link"
        active-class="_active"
        @click.native="showBurger(), gtmEvent('Главная')"
        :to="{ name: 'Home' }"
      >
        Главная
      </router-link>
      <a
        :href="`${publicPath}docs/rules.pdf`"
        target="_blank"
        @click="showBurger(), gtmEvent('Правила')"
        class="header__menu-link"
      >
        правила
      </a>
      <div
        class="header__menu-link to-prizes"
        active-class="_active"
        @click="showBurger(), goTo(), gtmEvent('Призы')"
      >
        призы
      </div>
      <router-link
        v-for="(item, idx) in navigation"
        :key="idx"
        class="header__menu-link"
        active-class="_active"
        @click.native="showBurger(), gtmEvent(item.name)"
        :to="{ name: item.path }"
      >
        {{ item.name }}
      </router-link>
      <router-link
        class="header__menu-link"
        active-class="_active"
        @click.native="gtmEvent('Вопросы и ответы'), showBurger()"
        :to="{ name: 'Faq' }"
      >
        вопросы и ответы
      </router-link>
      <a
        href="https://marspassion.ru/"
        target="_blank"
        class="btn btn--secondary"
        @click="gtmEvent('Архив')"
      >
        архив
      </a>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      showMenu: false,
      navigation: [
        {
          name: "Победители",
          path: "Winners",
        },
        {
          name: "Продукты",
          path: "Production",
        },
        {
          name: "Где купить",
          path: "WhereToBuy",
        },
        // {
        //   name: "Snickers®️ 30 лет в движении 2.0",
        //   path: "Home",
        // },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    clickPrize() {
      if (this.$store.getters.user) {
        let apmButton = document.querySelector(
          "#apm-scan-qr .apm-action-button"
        );
        if (apmButton) apmButton.click();
      } else {
        this.$modal.show("authorization");
      }
    },

    goTo() {
      this.$store.commit("SCROLL_TO_PRIZES", true);
      this.$router.push({ name: "Home" });
    },

    showModal(name) {
      this.$modal.show(name);
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    showBurger() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999999;

  &.header--mob_active {
    width: 100%;
  }
  .container {
    position: relative;
    padding-top: rem(18px);
    padding-bottom: rem(17px);
    z-index: 11;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__logo {
    height: rem(37px);
    &-wrapper {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  &__nav {
    position: relative;
    width: 100%;
    display: none;
    align-items: center;
  }
  &__hamburger {
    display: flex;
    align-items: center;
    font-size: rem(24px);
    flex-shrink: 0;
    margin-left: auto;
    text-transform: uppercase;
    line-height: 1;
    z-index: 100;
    pointer-events: all !important;
    cursor: pointer;
    &-text {
      display: block;
      flex-shrink: 0;
      margin-top: rem(3px);
      margin-right: rem(12px);
    }
    &-pic {
      width: rem(24px);
    }
  }

  &__menu {
    display: none;
    position: absolute;
    width: 100vw;
    top: 0px;
    right: 0;
    height: 100vh;
    padding-top: vh(80px);
    padding-bottom: rem(20px);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url("../assets/images/menu-bg-mob.jpg") no-repeat center bottom;
    background-size: cover;
    z-index: 1;
    &._active {
      display: flex;
    }
    &-link {
      font-family: "Impact", sans-serif;
      text-transform: uppercase;
      font-size: rem(18px);
      margin-bottom: vh(15px);
    }
    .btn {
      margin-top: rem(20px);
      width: rem(180px);
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  @media (min-width: $sm) {
    &__logo {
      height: rem(38px);
    }
  }
  @media (min-width: $md) {
    width: 100%;
    & > * {
      pointer-events: none;
    }
    &__row {
    }
    &__logo {
      height: vh(66px);
      pointer-events: all;
    }
    &__menu {
      padding-top: vh(80px);
      background: url("../assets/images/menu-bg.jpeg") no-repeat center;
      background-size: cover;
      & > * {
        pointer-events: all;
      }
      &-link {
        margin-bottom: vh(10px);
        font-size: vh(24px);
      }
    }
    .container {
      display: flex;
      padding-top: vh(32px);
      padding-bottom: 0;
    }
  }
}
</style>
