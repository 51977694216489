<template>
  <div class="faq page">
    <div class="container _small-width">
      <div class="faq__header">
        <h2 class="page__title">ответы <br />на вопросы</h2>
      </div>
      <div class="faq__row">
        <div class="faq__content">
          <div class="faq__list">
            <div
              class="faq__item"
              v-for="(item, index) in items"
              :key="index"
              :class="item.isActive ? 'faq__item--active' : ''"
            >
              <div
                class="faq__item-header"
                @click="item.isActive = !item.isActive"
              >
                <h2 class="faq__item-title">
                  <span v-html="item.title"></span>
                </h2>
                <div class="faq__item-trigger">
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.36001 2.69776L9.19075 10L16.0215 2.69776"
                      stroke="white"
                      stroke-width="3.65314"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="faq__item-content"
                v-if="item.isActive"
                v-html="item.text"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="faq-feedback">
        <div class="faq-feedback__title">Не нашел ответ на свой вопрос?</div>
        <div class="faq-feedback__text">
          напиши нам и мы обязательно ответим!
        </div>
        <button class="btn faq-feedback__btn" @click="$modal.show('feedback')">
          задать вопрос
        </button>
      </div>
    </div>
    <div class="widget _fixed">
      <div class="widget-scanner"></div>
      <div class="widget-button"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Кто является организатором акции? ",
        text: "Организатором акции является ООО &laquo;Едадил Промо&raquo;.",
        isActive: false,
      },
      {
        title: "Где проводится акция? ",
        text: "Акция проводится на&nbsp;территории Российской Федерации в&nbsp;сети Интернет посредством сервиса на&nbsp;территории Российской Федерации",
        isActive: false,
      },
      {
        title: "Какой общий срок проведения акции?",
        text: "с&nbsp;11.11.2024 года по&nbsp;28.02.2025 года (включительно), включая период выдачи призов Победителям.",
        isActive: false,
      },
      {
        title: "Какой срок регистрации чеков? ",
        text: "с&nbsp;11.11.2024 года по&nbsp;05.01.2025 года (включительно).",
        isActive: false,
      },
      {
        title: "Как я могу принять участие в Акции?",
        text: "В&nbsp;период c&nbsp;11.11.2024 по&nbsp;05.01.2025 года совершить нижеперечисленные действия:<br>&bull;Приобрести Продукцию, согласно разделу 2.1&nbsp;Правил, на&nbsp;территории Российской Федерации;<br>&bull;Зарегистрироваться в&nbsp;приложении &laquo;Едадил&raquo;;<br>&bull;Зайти в&nbsp;приложение &laquo;Едадил&raquo;;<br>&bull;Найти на&nbsp;Чеке QR-код;<br>&bull;Отсканировать QR-код в&nbsp;разделе &laquo;Кешбэк&raquo; Приложения &laquo;Едадил&raquo; или в&nbsp;мобильной версии;<br>&bull;Успешно пройти проверку Чека и&nbsp;получить Билетик для участия в&nbsp;розыгрыше",
        isActive: false,
      },
      {
        title: "Какие призы возможно выиграть в&nbsp;акции?",
        text: "&bull;	Еженедельные призы <br>&bull;	Mygift сертификат 500&nbsp;&mdash; 295&nbsp;шт<br>&bull;	Mygift сертификат 1000&nbsp;&mdash; 170&nbsp;шт<br>&bull;	Mygift сертификат 2000&nbsp;&mdash; 90&nbsp;шт<br>&bull;	Сертификат номиналом 2000 (две тысячи) рублей в&nbsp;онлайн сервис &laquo;Афиша&raquo;&nbsp;&mdash; 200&nbsp;шт<br>&bull;	Толстовка белая&nbsp;&mdash; 40&nbsp;шт<br>&bull;	Толстовка красная&nbsp;&mdash; 40&nbsp;шт<br>&bull;	Толстовка синяя&nbsp;&mdash; 40&nbsp;шт<br>&bull;	Главный приз&nbsp;&mdash; 100&nbsp;000&nbsp;руб. переводом на&nbsp;расчетный счет. Полная информация о&nbsp;количестве призов и&nbsp;периодах розыгрышей указана в&nbsp;правилах акции.",
        isActive: false,
      },
      {
        title: "Сколько призов я могу получить?",
        text: "Один Участник за&nbsp;весь срок проведения Акции может получить только один Приз.",
        isActive: false,
      },
      {
        title: "Где я&nbsp;могу посмотреть выигранные мною призы?",
        text: "Статус возможно отслежиать в&nbsp;сервисе &laquo;Едадил&raquo;.",
        isActive: false,
      },
      {
        title: "Как узнать, стал ли я обладателем приза?",
        text: "Участнику придет уведомление в&nbsp;сервисе &laquo;Едадил&raquo;.",
        isActive: false,
      },
      {
        title:
          "Я&nbsp;выиграл приз, какую информацию мне нужно сообщить организатору акции для получения приза?",
        text: "Для получения приза, стоимость которого не&nbsp;превышает либо равна 4&nbsp;000&nbsp;рублей, победитель обязан предоставить следующую информацию/документы:<br>&bull; ФИО, <br>&bull; номер мобильного телефона, <br>&bull; адрес электронной почты<br>&bull; адрес доставки приза <br>Для получения приза, стоимость которого превышает 4&nbsp;000&nbsp;рублей, победитель обязан предоставить следующую информацию/документы:<br>&bull; ФИО, <br>&bull; Номер мобильного телефона, <br>&bull; Адрес электронной почты, <br>&bull; Адрес доставки приза, <br>&bull; Сканированную копию паспорта гражданина Российской Федерации (основной разворот и&nbsp;страница с&nbsp;постоянной регистрацией),<br>&bull; Номер ИНН<br>&bull; Расписка о&nbsp;получении приза;<br>&bull; Шаблон для получения денежного перевода",
        isActive: false,
      },
      {
        title:
          "Я&nbsp;передал организатору все данные о&nbsp;себе. Когда и&nbsp;как мне пришлют приз?",
        text: "Mygift сертификат 500 , Mygift сертификат 1000, Mygift сертификат 2000, Сертификат номиналом 2000 (две тысячи) рублей в&nbsp;онлайн сервис &laquo;Афиша&raquo;&nbsp;&mdash; вручаются путем отправки на&nbsp;адрес электронной почты gобедителя. Толстовка красная, Толстовка белая, Толстовка синяя&nbsp;&mdash; вручается посредством курьерской отправки на&nbsp;адрес, указанный победителем в&nbsp;анкете. Главный приз&nbsp;&mdash; 100&nbsp;000 рублей вручается посредством перевода на&nbsp;расчетный счет победителя в&nbsp;срок до&nbsp;28.02.2025.",
        isActive: false,
      },
      {
        title: "Я могу вместо приза получить деньги?",
        text: "Призы не&nbsp;могут быть востребованы участниками повторно, денежная компенсация не&nbsp;выдается.",
        isActive: false,
      },
      {
        title: "Платит ли налог за приз победитель? ",
        text: "Нет, Оператор, при выдаче Призов: Толстовка красная, Толстовка белая, Толстовка синяя, Главный приз&nbsp;&mdash; 100&nbsp;000 рублей&nbsp;&mdash; Участникам, в&nbsp;соответствии с&nbsp;положениями Налогового Кодекса РФ&nbsp;выступает в&nbsp;отношении них налоговым агентом и&nbsp;исполняет обязанность по&nbsp;правильному и&nbsp;своевременному исчислению, удержанию из&nbsp;доходов в&nbsp;виде Призов, вручаемых Победителям, налога на&nbsp;доходы физического лиц (НДФЛ) и&nbsp;перечислению его в&nbsp;бюджет.",
        isActive: false,
      },
    ],
  }),
  methods: {},
  mounted() {
    this.$parent.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.faq {
  position: relative;
  z-index: 1;
  .container {
    position: relative;
    z-index: 1;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
  }
  &__content {
    width: 100%;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__item {
    position: relative;
    &:nth-child(2n) {
      .faq__item-header {
        &::before {
          display: none;
        }
      }
    }
  }

  &__item--active {
    .faq__item-trigger {
      .horizontal {
        opacity: 0;
      }
    }
  }

  &__item-header {
    cursor: pointer;
    position: relative;
    padding: rem(12px) rem(35px) rem(12px) rem(5px);
    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: calc(100% + #{rem(20px)});
      height: 100%;
      left: rem(-10px);
      background-color: #300808;
      transform: skew(170deg);
      z-index: -1;
    }
  }
  &__item-trigger {
    position: absolute;
    top: 50%;
    right: rem(5px);
    transform: translateY(-50%);
    width: rem(29px);
    height: rem(29px);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: rem(13px);
      height: rem(8px);
    }
    path {
      transition: 0.5s;
      ._pink & {
        fill: #fff;
      }
    }
    ._pink & {
      border-color: #fff;
    }
  }
  &__item-title {
    display: flex;
    font-size: rem(16px);
    line-height: 1;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
    span {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__item-content {
    width: 90%;
    padding: rem(15px) rem(0px) rem(15px);
    font-size: rem(15px);
    text-transform: none;
    font-family: "Avenir", sans-serif;
  }
  &-feedback {
    padding-top: rem(50px);
    font-family: "Impact", sans-serif;
    &__title {
      font-size: rem(18px);
      text-transform: uppercase;
    }
    &__text {
      margin-bottom: rem(16px);
      font-size: rem(14px);
    }
    &__btn {
      width: 100%;
      height: rem(34px);
      padding: 0;
      background: #e31913;
      box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.3);
      font-size: rem(16px);
    }
  }
  @media (min-width: $sm) {
    &__item-header {
      padding: rem(18px) rem(70px) rem(15px) 0;
      &:before {
        width: calc(100% + #{rem(47px)});
        left: auto;
        right: 0;
        transform: skew(160deg);
      }
    }
    &__item-content {
      padding: rem(15px) rem(70px) rem(15px) 0;
    }
    &__item-title {
      padding-right: rem(70px);
      font-size: rem(18px);
    }
    &__item-trigger {
      width: rem(38px);
      height: rem(38px);
    }
    &-feedback {
      padding-top: rem(62px);
      &__title {
        font-size: rem(24px);
        text-transform: uppercase;
      }
      &__text {
        margin-bottom: rem(23px);
      }
      &__btn {
        width: rem(395px);
        height: rem(60px);
        font-size: rem(26px);
      }
    }
  }
  @media (min-width: $md) {
    &__content {
      width: 60%;
    }
  }
}
</style>
<style lang="scss">
.link {
  color: $pink;
  text-decoration: underline;
  ._pink & {
    color: #fff;
  }
}
</style>
