import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/styles/main.scss";
import VModal from "vue-js-modal";
import typograf from "./utils/filters";
import vSelect from "vue-select";
import Vuelidate from "vuelidate";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "slick-carousel/slick/slick.css";

import VueTheMask from "vue-the-mask";
import "vue-select/src/scss/vue-select.scss";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);
Vue.filter("text", typograf);
Vue.component("v-select", vSelect);
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(Vuelidate);

Vue.mixin({
  methods: {
    gtmEvent: (label) => {
      window.dataLayer?.push({
        event: "gaEvent",
        eventLabel: label || "",
      });
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
