<template>
  <div class="page">
    <div class="container _small-width">
      <h2 class="page__title">продукты <br />участвующие <br />в акции</h2>
      <Slick
        ref="slider"
        :asNavFor="c2"
        :focusOnSelect="true"
        :options="settings"
        class="production-list"
      >
        <div
          v-for="(item, i) in production"
          :key="i"
          class="production-list__item"
        >
          <div class="production-list__item-img-wrapper">
            <img
              :src="require(`../assets/images/production/${item.img}`)"
              alt=""
              class="production-list__item-img"
            />
            <div class="production-list__item-popup">
              <svg
                class="production-list__item-popup-trigger"
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19.5" cy="19.5" r="9.5" fill="white" />
                <circle
                  cx="19.5"
                  cy="19.5"
                  r="18.5"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
              <div class="production-list__item-popup-content">
                <span>{{ item.title }}</span>
                <router-link
                  class="btn btn--default"
                  :to="{ name: 'WhereToBuy' }"
                  >Купить</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </Slick>
    </div>
    <Slick
      ref="sliderNav"
      :asNavFor="c1"
      :options="settingsNav"
      class="production-nav"
    >
      <div
        v-for="(item, i) in production"
        :key="`${i}_nav`"
        class="production-nav__item"
      >
        <div class="production-nav__item-content">
          <div class="production-nav__item-img-wrapper">
            <img
              :src="require(`../assets/images/production/${item.img}`)"
              alt=""
              class="production-nav__item-img"
            />
          </div>
          <div class="production-nav__item-title">
            {{ item.title | typograf }}
          </div>
        </div>
      </div>
    </Slick>
    <div class="widget _fixed">
      <div class="widget-scanner"></div>
      <div class="widget-button"></div>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";

export default {
  components: { Slick },
  data: () => ({
    c1: null,
    c2: null,
    settingsNav: {
      arrows: false,
      centerPadding: "90px",
      centerMode: true,
      infinite: true,
      slidesToShow: 5,
      asNavFor: ".production-list",
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            centerPadding: "100px",
            arrows: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "80px",
            arrows: true,
          },
        },
      ],
    },
    settings: {
      arrows: true,
      dots: false,
      slidesToShow: 1,
      asNavFor: ".production-nav",
      infinite: true,
      fade: false,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            arrows: false,
          },
        },
      ],
    },
    production: [
      {
        img: "product_10.png",
        title: "Шоколадный батончик SNICKERS®, 50,5г",
      },
      {
        img: "product_7.png",
        title: "Шоколадный батончик SNICKERS® Super, 80г",
      },
      {
        img: "product_2.png",
        title: "Шоколадный батончик SNICKERS® Super Белый, 81г",
      },
      {
        img: "product_3.png",
        title: "Шоколадный батончик SNICKERS® Super Лесной орех, 81г",
      },
      {
        img: "product_4.png",
        title: "Шоколадный батончик SNICKERS® Криспер с рисовыми шариками, 60г",
      },
      {
        img: "product_17.png",
        title: "Упаковка шоколадных батончиков Snickers® 180г",
      },
      {
        img: "product_16.png",
        title: "Упаковка шоколадных батончиков Snickers® 160г",
      },
      {
        img: "product_11.png",
        title: "Шоколадный батончик TWIX®, 55г",
      },
      {
        img: "product_8.png",
        title: "Шоколадный батончик TWIX® Xtra, 82г",
      },
      {
        img: "product_21.png",
        title: "Шоколадный батончик TWIX® Xtra солёная карамель 82г",
      },
      {
        img: "product_18.png",
        title: "Упаковка шоколадных батончиков TWIX® 165г",
      },
      {
        img: "product_19.png",
        title: "Упаковка шоколадных батончиков Twix® 184г",
      },
      {
        img: "product_9.png",
        title: "Шоколадный батончик MILKY WAY®, 26г",
      },
      {
        img: "product_5.png",
        title: "Шоколадный батончик MILKY WAY® 1+1, 52г",
      },
      {
        img: "product_22.png",
        title: "Шоколадный батончик MILKY WAY® Клубничный коктейль, 26г",
      },
      {
        img: "product_15.png",
        title: "Упаковка шоколадных батончиков MILKY WAY® 104г",
      },
      {
        img: "product_20.png",
        title: "Шоколадный батончик Mars® 40г",
      },
      {
        img: "product_6.png",
        title: "Шоколадный батончик MARS® Max, 81г",
      },
      {
        img: "product_13.png",
        title: "Упаковка шоколадных батончиков MARS® 162г",
      },
      {
        img: "product_14.png",
        title: "Упаковка шоколадных батончиков MARS® 182г ",
      },
      {
        img: "product_1.png",
        title: "Шоколадный батончик BOUNTY® Trio, 82,5г",
      },
      // {
      //   img: "product_23.png",
      //   title: "Шоколадный батончик BOUNTY® Манго, 55г",
      // },
      {
        img: "product_12.png",
        title: "Упаковка шоколадных батончиков Bounty® 165г",
      },
    ],
  }),
  mounted() {
    this.$parent.showFooter = true;
    this.c1 = this.$refs.slider;
    this.c2 = this.$refs.sliderNav;
  },
};
</script>

<style lang="scss">
.production {
  &-list {
    margin-bottom: rem(52px);
    overflow: visible;
    .slick-list {
      overflow: visible !important;
    }
    &__item {
      &-img {
        max-height: 100%;
        margin: auto;
        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: rem(200px);
        }
      }
      &-popup {
        position: absolute;
        left: 80%;
        bottom: 10%;
        &::after {
          content: "";
          position: absolute;
          top: rem(0px);
          left: rem(30px);
          width: rem(100px);
          height: rem(50px);
        }
        &:hover {
          & > .production-list__item-popup-content {
            pointer-events: all;
            opacity: 1;
          }
        }
        &-trigger {
          width: rem(40px);
          cursor: pointer;
          circle {
            transform-origin: center;
            &:nth-child(2) {
              animation: circleAnimate 1.5s infinite alternate ease-in;
            }
          }
        }
        &-content {
          position: absolute;
          top: rem(20px);
          right: 100%;
          display: flex;
          flex-direction: column;
          width: vw(255px, 480px);
          padding: rem(20px);
          background: #fff;
          color: #2c0f07;
          font-size: rem(16px);
          border-radius: rem(4px);
          transition: 0.4s;
          pointer-events: none;
          opacity: 0;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: calc(100% - #{rem(20px)});
            width: rem(30px);
            height: rem(30px);
            transform: scale(-1, 1);
            background: url("data:image/svg+xml,%3Csvg width='47' height='14' viewBox='0 0 47 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1018_117)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H19H22H251C253.209 0 255 1.79086 255 4V185.467C255 187.676 253.209 189.467 251 189.467H19C16.7909 189.467 15 187.676 15 185.467V14.7107L0 0Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1018_117'%3E%3Crect width='47' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
              no-repeat top left;
          }
          span {
            display: block;
            margin-bottom: rem(20px);
          }
        }
      }
    }
  }
  &-nav {
    margin-bottom: rem(50px);
    &__item {
      text-align: center;
      cursor: pointer;
      padding: 0 rem(5px);
      &-content {
        height: rem(130px);
        background: url("../assets/images/production/bg-1.png") no-repeat center;
        background-size: 100% 100%;
        border-radius: rem(4px);
        transition: 0.4s;
        .slick-current & {
          background: url("../assets/images/production/bg-2.png") no-repeat
            center;
          background-size: 100% 100%;
        }
      }
      &-title {
        padding: 0 rem(20px);
        font-size: rem(10px);
      }
      &-img {
        max-width: 90%;
        max-height: 100%;
        margin: auto;
        &-wrapper {
          display: flex;
          height: 60%;
        }
      }
    }
  }
  @media (min-width: $md) {
    &-list {
      margin-bottom: rem(75px);
      &__item {
        &-img {
          &-wrapper {
            max-width: 50%;
            height: 50vh;
            margin: auto;
          }
        }
        &-popup {
          left: 90%;
          bottom: 10%;
          &-content {
            top: rem(20px);
            left: rem(60px);
            width: vw(255px, 1152px);
            right: auto;
            &::before {
              top: 0;
              left: rem(-13px);
              width: rem(30px);
              height: rem(30px);
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    &-nav {
      margin-bottom: 0;
      &__item {
        padding: 0 rem(8px);
        &-content {
          height: rem(170px);
        }
        &-img {
          max-width: 90%;
          max-height: 100%;
          margin: auto;
          &-wrapper {
            display: flex;
            height: 60%;
          }
        }
        &-title {
          max-width: 100%;
          margin: auto;
          font-size: rem(14px);
          line-height: rem(16px);
        }
      }
    }
  }
}
@keyframes circleAnimate {
  from {
    opacity: 0.3;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
