<template>
  <label class="form-checkbox">
    <input
      type="checkbox"
      class="form-checkbox__input"
      @change="onInput()"
      v-model.trim="retValue"
    />
    <div class="form-checkbox__custom"></div>
    <div class="form-checkbox__caption" v-html="label"></div>
    <div class="error-hint _left" v-if="error">{{ error }}</div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    error: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  margin-bottom: rem(20px);

  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  &__input:checked + .form-checkbox__custom {
    background-color: $red;
    background-image: url("../../assets/images/icons/checked_icon.svg");
  }

  &__custom {
    min-width: rem(20px);
    width: rem(20px);
    height: rem(20px);
    background: #ffffff;
    background-size: rem(12px);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: rem(2px);
    transition: all 0.3s ease-in-out;
  }

  &__caption {
    font-size: rem(12px);
    line-height: rem(14px);
    margin-top: 1px;
    margin-left: rem(12px);
    a {
      color: $pink;
    }
  }
  @media (min-width: $md) {
    margin-bottom: 0;
    &__custom {
      min-width: rem(30px);
      width: rem(30px);
      height: rem(30px);
      background-size: rem(22px);
    }
    &__caption {
      margin-left: rem(21px);
      font-size: rem(16px);
    }
  }
}

.error .form-checkbox__custom {
  background: #ff9999;
}
</style>
