<template>
  <label
    class="form__field"
    :class="
      color === 'white'
        ? 'form__field--white'
        : '' || color === 'blue'
        ? 'form__field--blue'
        : ''
    "
  >
    <div class="form__caption" v-if="label">{{ label }}</div>
    <input
      v-if="maskValue != ''"
      :disabled="isDisabled"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValue"
      v-mask="maskValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />
    <input
      v-else
      :disabled="isDisabled"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />
    <div class="error-hint" v-if="error">{{ error }}</div>
  </label>
</template>
<script>
export default {
  data() {
    return {
      retValue: this.field,
      maskValue: this.mask || "",
      nameValue: this.name || "",
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },

    onBlur() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: String,
    mask: String,
    name: String,
    label: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    value: [String, Number],
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: () => {
        return "text";
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    size: {
      type: String,
      default: () => {
        return "sm";
      },
    },
    color: {
      type: String,
      default: () => {
        return "white";
      },
    },
  },

  components: {},
};
</script>
<style lang="scss" scoped>
.form__field--white {
  width: 100%;
  margin-bottom: rem(15px);
  @media (min-width: $md) {
    margin-bottom: rem(20px);
  }
}

.form__field-input {
  width: 100%;
  max-width: 100%;
  height: rem(34px);
  appearance: none;
  display: block;
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  box-shadow: 5.79px 5.79px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: rem(5px);
  padding: 0 rem(10px);
  font-size: rem(14px);
  line-height: 2.2rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  outline: none;
  color: #3c140a;
  &::placeholder {
    color: #806f6a;
  }
  &:focus {
    &::placeholder {
      color: #fff;
    }
  }
  @media (min-width: $md) {
    height: rem(60px);
    padding: 0 rem(15px);
    font-size: rem(18px);
    box-shadow: 0.9rem 0.9rem 0 rgb(0 0 0 / 25%);
  }
}
</style>
