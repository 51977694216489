<template>
  <div id="app">
    <Header v-if="showHeader" />
    <div class="app-content">
      <router-view />
    </div>
    <Footer v-if="showFooter" />
    <Feedback />
    <SuccessFeedback />
    <CommonError />
    <TemporarilyUnavailable />
    <AppEnterArrow v-if="$route.name === 'Home'" />
  </div>
</template>
<script>
import AppEnterArrow from "./components/AppEnterArrow.vue";
import Header from "@/components/AppHeader.vue";
import Footer from "@/components/AppFooter.vue";
import Feedback from "@/components/modals/feedback.vue";
import TemporarilyUnavailable from "@/components/modals/temporarilyUnavailable.vue";

import SuccessFeedback from "@/components/modals/successFeedback.vue";

import CommonError from "@/components/modals/commonError.vue";

export default {
  components: {
    Header,
    Footer,
    Feedback,
    SuccessFeedback,
    CommonError,

    TemporarilyUnavailable,
    AppEnterArrow,
  },
  data: function () {
    return {
      showHeader: true,
      showFooter: true,
      apmInit: false,
      network: null,
    };
  },
  methods: {
    checkAuth(t) {
      if (this.$store.getters.token) {
        if (!this.$store.getters.user) {
          this.$store.dispatch("GetProfile").then((response) => {
            if (response.error != 0) {
              if (this.$route.path !== "/") {
                this.$router.push("/");
              }
            } else {
              setTimeout(function () {
                t.apmInit = true;
              }, 1000);
            }
          });
        }
      }
    },
    gtmEventsOnPages(page) {
      const widgetButton = document.querySelector(".info-button__button");
      const widgetScan = document.querySelector(".scan-button__button");
      widgetButton.onclick = function () {
        window.dataLayer?.push({
          event: "gaEvent",
          category: "Check_reg_main_form_SC2_CB",
          action: "Check_reg_main_form_SC2_CB",
          label: page,
        });
      };
      widgetScan.onclick = function () {
        window.dataLayer?.push({
          event: "gaEvent",
          category: "Check_reg_main_form_SC2_CB",
          action: "Check_reg_main_form_SC2_CB",
          label: page,
        });
      };
    },
  },
  mounted() {
    // var vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty("--vh", `${vh}px`);
    // if (window.innerWidth > 768) {
    //   setInterval(() => {
    //     let buttonsWidth =
    //       document.querySelector(".info-button__button").offsetWidth +
    //       document.querySelector(".scan-button__button").offsetWidth;
    //     document.querySelector(".enter-action").style.width =
    //       buttonsWidth + 20 + "px";
    //   }, 1000);
    // } else {
    //   setInterval(() => {
    //     let buttonsWidth =
    //       document.querySelector(".info-button__button").offsetWidth +
    //       document.querySelector(".scan-button__button").offsetWidth;
    //     let arrows = document.querySelectorAll(".hero__action._on-mobile");
    //     for (let index = 0; index < arrows.length; index++) {
    //       const element = arrows[index];
    //       element.style.left = buttonsWidth / 1.25 + "px";
    //     }
    //   }, 1000);
    // }
    // if (this.$route.name != "Home") {
    //   this.gtmEventsOnPages(this.$route.name);
    //   document.querySelector(".e-root").classList.add("hidden");
    //   document.querySelector(".enter-action-wrapper").classList.add("hidden");
    // } else {
    //   document.querySelector(".e-root").classList.remove("hidden");
    //   document
    //     .querySelector(".enter-action-wrapper")
    //     .classList.remove("hidden");
    // }
    // var lastScrollTop = 0;
    // let userHasScrolled = false;
    // document.onscroll = function (e) {
    //     console.log('scroll');
    //   }
    // let widget = document.querySelector('.widget._fixed');
    // if(widget) {
    //   window.onscroll = function (e) {
    //     userHasScrolled = true;
    //   }
    //   setInterval(() => {
    //   if(userHasScrolled == true)
    //     {
    //       userHasScrolled = false;
    //       widget.classList.remove('_hide')
    //     }
    //   }, 250);
    //   document.addEventListener("scroll", function(){
    //     var st = window.pageYOffset || document.documentElement.scrollTop;
    //     if (st > lastScrollTop){
    //         widget.classList.add('_hide')
    //     } else {
    //         widget.classList.remove('_hide')
    //     }
    //     lastScrollTop = st <= 0 ? 0 : st;
    //   }, false);
    // }
    // function setHeight() {
    //   var vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    // }
    // setHeight();
    // window.addEventListener('resize', setHeight);
  },
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || "";
      },
      immediate: true,
    },
    "$route.name": function () {
      if (this.$route.name != "Home") {
        this.gtmEventsOnPages(this.$route.name);
        document.querySelector(".e-root").classList.add("hidden");
        document.querySelector(".enter-action-wrapper").classList.add("hidden");
      } else {
        document.querySelector(".e-root").classList.remove("hidden");
        document
          .querySelector(".enter-action-wrapper")
          .classList.remove("hidden");
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
}
.e-root {
  padding-left: rem(20px) !important;
  @media (min-width: $sm) {
    padding-bottom: 67px !important;
  }
}
.e-root__buttons {
  justify-content: flex-start !important;
  @media (max-width: $sm) {
    transform: scale(0.7);
    transform-origin: left bottom;
  }
  @media (min-width: $lg) {
    max-width: 95%;
    margin: auto;
  }
}
.scan-button__wrapper {
  @media (max-width: $sm) {
    margin-left: 3px !important;
  }
}
</style>
