<template>
  <div class="page coming-soon">
    <div class="container">
      <img
        src="../assets/images/coming-soon.png"
        alt=""
        class="coming-soon__img hidden-xs"
      />
      <img
        src="../assets/images/coming-soon-mob.png"
        alt=""
        class="coming-soon__img visible-xs"
      />
      <div class="coming-soon__text">
        и&nbsp;получай возврат до&nbsp;5% <br />от&nbsp;покупки брендов
        участников
      </div>
      <div class="counter-title">ДО СТАРТА КАМПАНИИ</div>
      <div class="counter">
        <div class="counter__item">
          <div class="counter__number" v-html="days"></div>
          <span>{{ daysText }}</span>
        </div>
        <div class="counter__item">
          <div class="counter__number" v-html="hours"></div>
          <span>{{ hoursText }}</span>
        </div>
        <div class="counter__item">
          <div class="counter__number" v-html="minutes"></div>
          <span>{{ minutesText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      days: null,
      daysText: null,
      hoursText: null,
      minutesText: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null,
      endDate: new Date("2023-11-04T23:59:21.817Z"),
    };
  },
  methods: {
    wordVariant(i, ent, v1, v2, v3) {
      const items = Number(i);
      this[ent] =
        items === 1 ||
        items === 21 ||
        items === 31 ||
        items === 41 ||
        items === 51
          ? v1
          : (items > 1 && 5 > items) || (items > 21 && 25 > items)
          ? v2
          : v3;
    },
    updateRemaining(distance) {
      const daysNum = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursNum = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesNum = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );

      this.days = `<span class='num'>${
        daysNum.toString().charAt(1) ? daysNum.toString().charAt(0) : 0
      }</span><span class='num'>${
        daysNum.toString().charAt(1) || daysNum.toString().charAt(0)
      }</span>`;
      this.wordVariant(daysNum, "daysText", "день", "дня", "дней");

      this.hours = `<span class='num'>${
        hoursNum.toString().charAt(1) ? hoursNum.toString().charAt(0) : 0
      }</span><span class='num'>${
        hoursNum.toString().charAt(1) || hoursNum.toString().charAt(0)
      }</span>`;
      this.wordVariant(hoursNum, "hoursText", "час", "часа", "часов");

      this.minutes = `<span class='num'>${
        minutesNum.toString().charAt(1) ? minutesNum.toString().charAt(0) : 0
      }</span><span class='num'>${
        minutesNum.toString().charAt(1) || minutesNum.toString().charAt(0)
      }</span>`;
      this.wordVariant(minutesNum, "minutesText", "минута", "минуты", "минут");
    },

    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
        this.isEnded = true;
      }
    },
  },
  mounted() {
    this.$parent.showFooter = true;
    this.$parent.showHeader = false;
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },
  destroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.coming-soon {
  &.page {
    display: flex;
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    align-items: center;
    &::before {
      display: none;
    }
  }
  &__text {
    margin-bottom: rem(42px);
    font-family: "Impact";
    font-size: rem(14px);
    line-height: rem(17px);
    text-align: center;
    text-transform: uppercase;
  }
  &__img {
    margin: auto;
  }
  @media (min-width: $md) {
    &.page {
      height: 100vh;
      padding-top: rem(55px);
    }
    &__img {
      height: 66vh;
      margin: auto;
    }
    &__text {
      margin-top: vh(-30px);
      margin-bottom: vh(18px);
      font-size: vh(24px);
      line-height: vh(28px);
    }
  }
  .counter {
    display: flex;
    justify-content: center;
    &-title {
      margin-bottom: rem(6px);
      text-align: center;
      font-size: rem(9px);
    }
    &__item {
      text-align: center;
      margin: 0 rem(5px);
      span {
        font-size: rem(9px);
        text-transform: uppercase;
      }
    }
    &__number {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(46px);
      height: rem(32px);
      padding-top: rem(3px);
      // padding-left: rem(3px);
      color: #200082;
      // letter-spacing: 0.3em;
      .num {
        display: block;
        flex: 50%;
        font-size: rem(33px);
        line-height: 1;
        text-align: center;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: rem(50px);
        height: rem(37px);
        background: url("../assets/images/counter-bg.png") no-repeat center;
        background-size: contain;
        z-index: -1;
      }
    }
  }
}
</style>
