<template>
  <div>
    <div class="home">
      <Hero />
      <Steps />
      <AppPrizes />
      <Footer />
    </div>
    
  </div>
</template>

<script>
import AppPrizes from "../components/AppPrizes.vue";
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
import Footer from "@/components/AppFooter.vue";
export default {
  data: function () {
    let self = this;
    return {
      publicPath: process.env.BASE_URL,
      currentSection: null,
      parameters: {
        event: "gaEvent",
        category: "Check_reg_main_form_CB",
        action: "Check_reg_main_form_CB",
        label: "main",
      },
      options: {
        licenseKey: "3A5AE2BE-1BFA45B0-A3D3D611-C6EE3A3D",
        responsiveWidth: 1024,
        fitToSectionDelay: 0,
        fixedElements: ".header, .e-root, .hero__action, .enter__action",
        sectionSelector: ".section",
        recordHistory: false,
        onLeave: function (origin, destination, direction) {},
        afterLoad: function (section, origin) {
          self.gtmEvent(`scroll_to_screen_${origin.index + 1}`);
          setTimeout(() => {
            const widgetButton = document.querySelector(".info-button__button");
            const widgetScan = document.querySelector(".scan-button__button");
            const enterArrow = document.querySelector(".enter-action");

            if (origin.index == 2) {
              enterArrow.classList.add("_top");
              // widgetButton.onclick = function () {
              //   window.dataLayer?.push({
              //     event: "gaEvent",
              //     eventCategory: "Check_reg_main_form_SC2_CB",
              //     eventAction: "Check_reg_main_form_SC2_CB",
              //     eventLabel: "screen_2",
              //   });
              // };
              // widgetScan.onclick = function () {
              //   window.dataLayer?.push({
              //     event: "gaEvent",
              //     eventCategory: "Check_reg_main_form_SC2_CB",
              //     eventAction: "Check_reg_main_form_SC2_CB",
              //     eventLabel: "screen_2",
              //   });
              // };
            } else {
              enterArrow.classList.remove("_top");
              // widgetButton.onclick = function () {
              //   window.dataLayer?.push({
              //     event: "gaEvent",
              //     eventCategory: "Check_reg_main_RegCheck_SC2_CB",
              //     eventAction: "Check_reg_main_RegCheck_SC2_CB",
              //     eventLabel: "main",
              //   });
              // };
              // widgetScan.onclick = function () {
              //   window.dataLayer?.push({
              //     event: "gaEvent",
              //     eventCategory: "Check_reg_main_RegCheck_CB",
              //     eventAction: "Check_reg_main_RegCheck_CB",
              //     eventLabel: "main",
              //   });
              // };
            }
          }, 1500);
        },
      },
    };
  },

  mounted() {
    this.$parent.showFooter = false;
    const $this = this;
    document.querySelector(".to-prizes").addEventListener("click", function () {
      $this.$refs.fullpage.api.moveTo(3);
      $this.$store.commit("SCROLL_TO_PRIZES", false);
    });
    if ($this.$store.getters.scrollToPrizes) {
      $this.$refs.fullpage.api.moveTo(3);
      $this.$store.commit("SCROLL_TO_PRIZES", false);
    }
    const snapper = document.querySelector(".home");
    const all = document.querySelectorAll('.section');
    all.forEach((element, index) => {
      element.classList.remove("active");
      
        element.addEventListener("wheel", function(e) {
          console.log('snapper.scrollTop', snapper.scrollTop);
          console.log('element.scrollHeight', element.scrollHeight);
          console.log('snapper.scrollHeight / all.length', ((snapper.scrollHeight / all.length) * (index + 1)));
          console.log('e.deltaY', e.deltaY);
          if (all.length > index + 1) {
            all[index + 1].classList.add("active");
          }
         
            

        })
    })
  },
  watch: {
    currentSection: function (val) {
      console.log("val" + val);
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    playVideo(name) {
      let videoElem = document.getElementById(name);
      videoElem.play();
    },
  },

  components: {
    Hero,
    Steps,
    Footer,
    AppPrizes,
  },
};
</script>

<style lang="scss">
.home {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden !important;
  scroll-snap-type: y mandatory;
  .section,.footer {
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
}
</style>
